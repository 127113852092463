import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import './App.scss';

function App() {
    return (
        <div className="App">
            <Header/>

            <Footer/>
        </div>
    );
}

export default App;

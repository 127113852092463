import LocationsSelector from "../Locations/LocationsSelector";
import Logo from "../Logo/Logo";
import "./Header.scss";

function Header() {
    return (
        <header>
            <div className="_bg"/>

            <div className="_contents">
                <Logo/>

                <div className="_text">
                    <h1>This website contains information for
                        healthcare professionals in Europe on LYTGOBI<sup>&reg;</sup>
                    </h1>

                    <p className="h2">
                        Please select your country:
                    </p>

                    <LocationsSelector/>

                    <div className="_separator"/>

                    <h3>What's on this site:</h3>

                    <div className="_cols">
                        <div>
                            <ul>
                                <li>Cholangiocarcinoma Challenges</li>
                                <li>Mechanism of Action</li>
                                <li>Efficacy & Safety</li>
                            </ul>
                        </div>

                        <div>
                            <ul>
                                <li>Continuous Dosing</li>
                                <li>Self Directed Learning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
import {Locations} from "../../config";
import Location from "./Location";
import "./LocationSelector.scss";

function LocationsSelector() {
    return (
        <div className="LocationsSelector">
            {Locations.map(site => {
                return (
                    <Location {...site} key={site.label}>{site.label}</Location>
                )
            })}
        </div>
    );
}

export default LocationsSelector;
import taiho from "./TOEUGMBH_A.png";
import "./Footer.scss";

function Footer() {
    return (
        <footer>
            <div className="_cols">
                <div>
                    <img src={taiho} alt={"TAIHO ONCOLOGY EUROPE"} className="_logo"/>

                    <ul className="_menu">
                        <li><a href={"https://www.taihooncology.eu/legal-notice/"} target={"_blank"} rel={"noreferrer"}>Terms of Use</a></li>
                        <li><a href={"https://www.taihooncology.eu/privacy-policy/"} target={"_blank"} rel={"noreferrer"}>Privacy Policy</a></li>
                        <li><a href={"https://www.taihooncology.eu/cookies-policy/"}  target={"_blank"} rel={"noreferrer"}>Cookie Policy</a></li>
                    </ul>

                    <p>If you would like to request further medical information, please&nbsp;
                        <a href={"https://www.taihooncology.eu/our-products/medical-information/"}>click here</a>.
                    </p>

                    <p>LYT-PM-TOE-0007<br/>
                        Date of preparation: May 2024<br/>
                        &copy; 2024, Taiho Oncology. All rights reserved.<br/>
                        Marketing authorisation holder:&nbsp;Taiho Pharma Netherlands B.V.<br/>
                        LYTGOBI, the LYTGOBI logo and the Taiho Oncology logo are registered trademarks of Taiho Oncology.
                    </p>
                </div>

                <div>
                    <div className="_box">
                        <p>
                            Adverse events should be reported to Taiho Oncology immediately by phoning <a href={"tel:+41215600764"}>
                            +41 21 560 07 64</a> (or use local numbers listed on this website&nbsp;
                            <a href={"https://www.taihooncology.eu/our-products/medical-information/"} target={"_blank"} rel={"noreferrer"}>
                                https://www.taihooncology.eu/our-products/medical-information/</a>) or via email at&nbsp;
                            <a href={"mailto:medicalinformation@taiho.eu"}>medicalinformation@taiho.eu</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;